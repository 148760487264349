<template>
    <div class="col-xxl-8 col-lg-12 box-col-12">
        <div class="card">
            <div class="card-header card-no-border">
                <h5 v-if="data && data.role!='super-admin'">Users</h5>
                <h5 v-else >TMA</h5>
            </div>
            <div class="card-body pt-0">
                <div class="row m-0 overall-card">
                    <div class="col-xl-9 col-md-12 col-sm-7 p-0">
                        <div class="chart-right">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card-body p-0">
                                        <ul class="balance-data">
                                            <!-- <li>
                                            <span class="circle bg-warning"> </span><span class="f-light ms-1">Earning</span>
                                        </li>
                                        <li>
                                            <span class="circle bg-primary"> </span><span class="f-light ms-1">Expense</span>
                                        </li> -->
                                        </ul>
                                        <div class="current-sale-container" v-if="isDataLoaded">
                                            <apexchart height="305" type="bar" :options="options3" :series="series3">
                                            </apexchart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-5 p-0">
                        <div class="row g-sm-4 g-2">
                            <div class="col-xl-12 col-md-4">
                                <div class="light-card balance-card widget-hover">
                                    <div class="svg-box">
                                        <img src="/3898372_user_people_man_add_icon.svg" alt="Image Description"
                                            class="img-fluid" />
                                    </div>
                                    <div>
                                        <span class="f-light">Active</span>
                                        <h6 class="mt-1 mb-0">{{ data?.overAllBalenceCard?.active }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-md-4">
                                <div class="light-card balance-card widget-hover">
                                    <div class="svg-box">
                                        <img src="/3898372_user_people_man_add_icon.svg" alt="Image Description"
                                            class="img-fluid" />
                                    </div>
                                    <div>
                                        <span class="f-light">Inactive</span>
                                        <h6 class="mt-1 mb-0">{{ data?.overAllBalenceCard?.inactive }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-md-4">
                                <div class="light-card balance-card widget-hover">
                                    <div class="svg-box">
                                        <img src="/3898372_user_people_man_add_icon.svg" alt="Image Description"
                                            class="img-fluid" />
                                    </div>
                                    <div>
                                        <span class="f-light" v-if="data && data.role && data.role=='super-admin'">Total Users</span>
                                        <span class="f-light" v-else>Active Citizen</span>
                                        <h6 class="mt-1 mb-0">{{ data?.overAllBalenceCard?.activeCitizen }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDown1 from "../base/dropdown/DropDown1.vue"
import {
    overallbalance
} from "../../../data/dashboard/default"
import {
    apexDashboard,
    chart
} from "../../../data/comon";
import { months } from "moment";
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        chart: [],
    },
    components: {
        DropDown1
    },
    watch: {
        data: {
            handler(newVal) {
                if (newVal && Object.keys(newVal).length > 0) {
                    this.isDataLoaded = true;
                }
            },
            immediate: true
        },

        'data.overAllBalenceCard': {
            handler(newVal) {
                if (newVal) {
                    this.options3.xaxis.categories = newVal.months;
                    this.series3[0].data = newVal.chartData;

                }
            },
            deep: true
        }
    },
    data() {
        return {
            apexDashboard: apexDashboard,
            overallbalance: overallbalance,
            isDataLoaded: false,
            options3: {
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    dropShadow: {
                        enabled: true,
                        top: 8,
                        left: 0,
                        blur: 10,
                        color: '#7064F5',
                        opacity: 0.1
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '25px',
                        borderRadius: 0,
                    },
                },
                grid: {
                    show: true,
                    borderColor: 'var(--chart-border)',
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 2,
                    dashArray: 0,
                    lineCap: 'butt',
                    colors: "#fff",
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    show: false
                },
                states: {
                    hover: {
                        filter: {
                            type: 'darken',
                            value: 1,
                        }
                    }
                },
                colors: ['#7366ff', '#AAAFCB'],
                yaxis: {
                    tickAmount: 3,
                    labels: {
                        show: true,
                        style: {
                            fontFamily: 'Rubik, sans-serif',
                        },
                       
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: ["jan", "feb", "march", "apr", "may", "june"],
                    labels: {
                        style: {
                            fontFamily: 'Rubik, sans-serif',
                        },
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'darken',
                            value: 1,
                        }
                    }
                },
                responsive: [{
                    breakpoint: 1661,
                    options: {
                        chart: {
                            height: 290,
                        }
                    }
                },
                {
                    breakpoint: 767,
                    options: {
                        plotOptions: {
                            bar: {
                                columnWidth: '35px',
                            },
                        },
                        yaxis: {
                            labels: {
                                show: false,
                            }
                        }
                    }
                },
                {
                    breakpoint: 481,
                    options: {
                        chart: {
                            height: 200,
                        }
                    }
                },
                {
                    breakpoint: 420,
                    options: {
                        chart: {
                            height: 170,
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: '40px',
                            },
                        },
                    }
                },
                ]
            },
            series3: [{
                name: 'Users',
                data: [2, 4, 5, 1, 6, 7]
            },
            ],
        };
    },
}
</script>
