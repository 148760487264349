<template>
    <div class="col-xxl-4 col-md-6 appointment-sec box-col-6">
        <div class="appointment">
            <div class="card">
                <div class="card-header card-no-border">
                    <div class="header-top">
                        <h5 class="m-0">Recent Projects</h5>
                        <div class="card-header-right-icon">

                        </div>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="appointment-table table-responsive">
                        <table class="table table-bordernone">
                            <tbody>
                                <tr v-if="data?.projects?.length === 0">
                                    <td colspan="3" class="text-center">No Projects Added</td>
                                </tr>
                                <tr v-for="item in data.projects" :key="item">
                                    <td>
                                        <img v-if="item?.thumbnail?.image_url" class="img-fluid img-40 rounded-circle"
                                            :src="item.thumbnail.image_url" alt="user" />
                                        <img v-else class="img-fluid img-40 rounded-circle"
                                            src="../../../assets/images/no-image-icon.png" alt="user" />
                                    </td>
                                    <td class="img-content-box">
                                        <a class="d-block f-w-500" href="user-profile.html">{{ item.project_name
                                            }}</a><span class="f-light">{{ item.project_date }}</span>
                                    </td>
                                    <td class="text-end">
                                        <p class="m-0 font-success">{{ item.amount }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDown2 from "../base/dropdown/DropDown2.vue";
import {
    resentSales
} from "../../../data/dashboard/default"
import {
    apexDashboard
} from "../../../data/comon";
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        chart: [],
    },
    components: {
        DropDown2
    },
    data() {
        return {
            apexDashboard: apexDashboard,
            resentSales: resentSales
        };
    },
}
</script>
