<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/app-users" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit User</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addUser">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Full Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control form-control-sm mb-3" required
                                        v-model="admin.name" />
                                </div>
                                <div class="col-md-6">
                                    <label>Occupation</label>
                                    <input type="text" class="form-control form-control-sm mb-3"
                                        v-model="admin.occupation" />
                                </div>
                            </div>



                            <div class="form-group">
                                <label>Cnic <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.cnic">
                                    {{ errors.cnic && errors.cnic.length > 0 ? errors.cnic[0] :
                                        '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.cnic"
                                    @keypress="isNumber($event)" maxlength="13" minlength="13" required />
                            </div>


                            <div class="form-group">
                                <label>Phone <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.phone_number">
                                    {{ errors.phone_number && errors.phone_number.length > 0 ? errors.phone_number[0] :
                                        '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3"
                                    v-model="admin.phone_number" maxlength="11" minlength="11"
                                    @keypress="isNumber($event)" required />
                            </div>



                            <div class="form-group">
                                <label>Area <span class="text-danger">*</span></label>
                                <select class="custom-select mb-3" v-model="admin.area_id" required>
                                    <option value="">Select...</option>
                                    <option v-for="area in areas" :value="area.id">
                                        {{ area.name }}
                                    </option>
                                </select>
                            </div>




                            <div class="form-group row">
                                <div class="col-md-12">
                                    <label>Date of birth <span class="text-danger">*</span></label>
                                    <input type="date" class="form-control form-control-sm mb-3" required
                                        v-model="admin.date_of_birth" />
                                </div>
                                <div class="col-md-12">
                                    <label>Gender <span class="text-danger">*</span></label>
                                    <div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gender" id="male"
                                                value="Male" v-model="admin.gender" required>
                                            <label class="form-check-label" for="male">Male</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gender" id="female"
                                                value="Female" v-model="admin.gender" required>
                                            <label class="form-check-label" for="female">Female</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gender" id="other"
                                                value="Other" v-model="admin.gender" required>
                                            <label class="form-check-label" for="other">Other</label>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.email">
                                    {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                                </span>
                                <input type="email" class="form-control form-control-sm mb-3" required
                                    v-model="admin.email" />
                            </div>

                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import isNumber from "@/helpers/helpers";
import router from "@/router";
import moment from 'moment';

export default {
    mixins: [isNumber],
    components: {
        QuillEditor,
    },
    data() {
        return {
            admin: [],
            areas: [],
            newsId: null,
            loading: false,
            modules: [],
            users: [],
            errors: [],
            userID: "",
        };
    },
    mounted() {
        this.userID = this.$route.params.id;
        this.fetch(this.userID);
        this.fetchUsers();
        this.fetchDistricts();
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("users/getSingle", { id })
                .then((response) => {
                    if (response === null || response.data === null) {
                        router.push({ path: "/app-users" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    } else {
                        this.admin = response.data;
                        this.admin = { ...this.admin, ...this.admin.profile };
                        delete this.admin.profile;
                        this.admin.date_of_birth = moment(this.admin.date_of_birth, 'DD-MM-YYYY').format('YYYY-MM-DD');
                        this.loading = false;
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.admin);
            data.type = "web";
            const id = this.userID;
            this.$store
                .dispatch("users/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        fetchUsers() {
            this.loading = true;
            this.$store
                .dispatch("users/getAll")
                .then((response) => {
                    this.loading = false;
                    this.users = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        fetchDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/showDistrictData")
                .then((response) => {
                    this.admin.district_id = response.data.district_id;
                    this.admin.tehsil_id = response.data.tehsil_id;
                    this.areas = response.data.areas;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>